import React from 'react';
import { CSSTransition } from 'react-transition-group';

interface Props {
    isVisible: boolean;
    onExited?: () => void;
}

const Animator: React.FC<Props> = ({ isVisible, children, ...props }) => {
    return (
        <CSSTransition
            {...props}
            in={isVisible}
            timeout={220}
            classNames={'checkout'}
            unmountOnExit={true}
            appear={true}
        >
            <div>
                {children}
            </div>
        </CSSTransition>
    );
};

export default Animator;
