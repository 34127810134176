import React from 'react';
import UnsubscribeComponent from '../components/Unsubscribe';
import styled from 'styled-components';
import Logo from '../components/Logo';
import MinimalLayout from '../components/layouts/MinimalLayout';

const Unsubscribe: React.FC = () => {
    return (
        <MinimalLayout>
            <UnsubscribeComponent />
        </MinimalLayout>
    );
};

export default Unsubscribe;
