import Icon from '@mdi/react';
import Spinner from './Spinner';
import Animator from './Animator';
import styled from 'styled-components';
import { getUrlParams } from '../util/url';
import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { modularScale } from '../util/modularScale';
import { grey, primary, secondary } from '../util/theme';
import { mdiCheckCircleOutline, mdiEmailCheckOutline, mdiEmailOutline, mdiEmailRemoveOutline } from '@mdi/js';
import axios from 'axios';

const Container = styled.div`
	width: 100%;
	max-width: 500px;
	margin: 1rem auto 6rem;
	
	@media (min-width: 600px) {
	    margin-top: 3rem;
	}
`;

const Dialog = styled.div`
	background: #fff;
	padding: 1rem;
	border-radius: 4px;
	display: flex;
	align-items: center;
	flex-direction: column;
	
	@media (min-width: 500px) {
	    padding: 4rem;
	}
	
	@media (min-width: 900px) {
	    padding: 4rem;
	}
	
	svg {
	    color: ${secondary};
	    width: 40px;
	}
	
	p {
	    margin-bottom: 2rem;
	}
`;

const StyledButton = styled(Button)`
	margin-bottom: 1rem;
	color: #fff !important;
	background-color: #000;
	height: 42px;
	width: 110px;
	
	&.Mui-disabled {
	    background-color: #acacac;
	    
            &:hover, &:focus, &:active {
            background-color: #acacac;
        }
	}
	
	&:hover, &:focus, &:active {
	    background-color: #000;
	}
	
	svg {
	    width: 24px;
	}
`;

const Aside = styled.div`
    text-align: center;
	margin-top: 2rem;
`;

const Help = styled.div`
    text-align: center;
	font-size: 11px;
	color: ${grey};
`;

const Divider = styled.hr`
	width: 24px;
	border: 0;
	border-bottom: 1px solid ${grey};
	margin: 2rem 0;
`;

const Unsubscribe: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('verifying');
    const [showResult, setShowResult] = useState(false);

    useEffect(() => {
        const urlParams: any = getUrlParams(document.location.search);

        setEmail(urlParams.email);
    }, []);

    const unsub = async () => {
        setIsLoading(true);

        try {
            await axios.delete('/email', { data: { email } })
        } catch (err) {
            console.log(err);
        }

        setStatus('verified');
    };

    const getButtonText = () => {
        if (isLoading) {
            return <Spinner color={'#fff'} />
        }

        return 'Unsubscribe';
    }

    return (
        <Container>
            <Animator isVisible={status === 'verifying'} onExited={() => setShowResult(true)}>
                <Dialog>
                    <Icon path={mdiEmailRemoveOutline} />
                    <h1>Are you sure?</h1>
                    <p>Please click the unsubscribe button to unsubscribe <strong>{email}</strong> from all Pauliano mailing lists.</p>
                    <StyledButton onClick={unsub} variant="contained" disabled={isLoading}>{getButtonText()}</StyledButton>
                </Dialog>
            </Animator>
            <Animator isVisible={showResult && status === 'verified'}>
                <Dialog>
                    <h1>Miss you already.</h1>
                    <p>(But don't worry, we won't take it personally.)</p>
                    <Icon path={mdiEmailCheckOutline} />
                    <Aside>We understand that you want to spend less time in your inbox. Let's connect on <a href='https://www.instagram.com/paulianomusic' target="_blank">Instagram</a> instead!</Aside>
                    <Divider />
                    <Help>You have been unsubscribed from this list. You may still receive transactional messages concerning your Pauliano account.</Help>
                </Dialog>
            </Animator>
        </Container>
    );
};

export default Unsubscribe;
